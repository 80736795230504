/* Table */
.general-table-search .ant-input-group .ant-input-group-addon:first-child {
  background: #e5e5eaa6;
  border-color: #e5e5eaa6;
}

.general-table-search .ant-input-affix-wrapper {
  border-color: #e5e5eaa6;
}

.general-table-search
  .ant-input-group
  .ant-input-group-addon:first-child
  .ant-select-single
  .ant-select-selector {
  color: #000;
  border-color: #d1d1d633;
}

.general-table-search .ant-select .ant-select-selection-item {
  font-weight: 600;
}

.general-table-search .ant-input-group .ant-input-group-addon:last-child {
  padding: 0px;
  border-color: #e5e5eaa6;
}

.general-table-search
  .ant-input-group
  .ant-input-group-addon:last-child
  svg
  path {
  fill: #000;
}

.general-table-select .ant-select-selector {
  border-color: #e5e5eaa6 !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: transparent;
  color: #aeaeb2;
  font-weight: 500;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-item {
  height: 24px;
  min-width: 26px;
  line-height: 24px;
  background-color: #e5e5ea61;
  border-color: #e5e5eab3;
  border-radius: 4px;
  font-size: 12px;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #0318d6;
}

.ant-pagination .ant-pagination-item-active > a,
.ant-pagination .ant-pagination-item-active:hover > a {
  color: #fff;
}

.ant-pagination .ant-pagination-jump-next {
  line-height: 24px;
  height: 24px;
  min-width: 26px;
}

.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #000;
  font-size: 12px;
  letter-spacing: 0.5px;
}

/* Collapse */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-direction: row-reverse;
  font-size: 16px;
  font-weight: 500;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  padding-inline-end: 0px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

/* Form */
.ant-form-item .ant-form-item-label > label {
  flex-direction: row-reverse;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-inline-end: 0px;
  margin-inline-start: 4px;
}

.ant-form-item:last-child {
  margin-bottom: 0px;
}

/* Datepicker */
.custome-date-picker-icon .ant-picker > div {
  order: 2;
}

.custome-date-picker-icon .ant-picker .ant-picker-suffix {
  margin-inline-end: 8px;
  margin-inline-start: 0px;
}

/* curvy-drawer */
.curvy-drawer.curvy-drawer {
  border-radius: 38px 0px 0px 38px;
}

.curvy-drawer .ant-drawer-content-wrapper.ant-drawer-content-wrapper {
  border-radius: 38px 0px 0px 38px;
  right: 81px;
  background: transparent !important;
}

.curvy-drawer .ant-drawer-content {
  border-radius: 38px 0px 0px 38px;
}

.curvy-drawer .ant-drawer-content-wrapper {
  border-radius: 38px 0px 0px 38px;
}

.curvy-drawer .ant-drawer-mask.ant-drawer-mask {
  right: 81px;
}

.curvy-drawer .ant-drawer-close {
  position: absolute;
  top: 23px;
  left: -36px;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
}

/* Modal */

.ant-modal .ant-modal-close {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 627px) {
  .curvy-drawer .ant-drawer-header {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .curvy-drawer .ant-drawer-content-wrapper.ant-drawer-content-wrapper {
    right: 0px;
  }
  .curvy-drawer .ant-drawer-mask.ant-drawer-mask {
    right: 0px;
  }
}
