.auth-view {
  height: 100vh;
}

.auth-view .auth-view-image {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.login-main {
  max-width: 370px;
  min-height: 100vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.login-main-container {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 2rem 1rem;
}

.login-text {
  font-size: 2.4rem;
  background: transparent linear-gradient(20deg, #8fcaf3 -20%, #3a5ee3 100%);
  background-clip: text !important;
  color: transparent !important;
}

.login-card {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 16px;
  max-width: 370px;
  margin-top: 32px;
}

.login-card .ant-tabs {
  height: initial !important;
  width: 100%;
}

.login-card .ant-tabs-tab {
  padding: 24px 0 17px !important;
}

.login-card.ant-tabs-nav {
  height: initial !important;
}

.login-card .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.login-card .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
}

.login-card .ant-tabs-tab {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-card .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}

.login-card .ant-tabs-tab div span {
  color: #d1d1d6;
}

.login-card .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none !important;
}

.ant-tabs-tab-active div .ant-typography {
  color: #000 !important;
}

.login-inputs {
  background-color: #f2f2f7 !important;
  border-radius: 14px !important;
  border: none !important;
}

.login-inputs .ant-input {
  background: transparent;
}

.login-main .sign-in-btn {
  width: 100%;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  border-radius: 14px;
}

.login-card .ant-form-item-label > label {
  color: #8e8e93 !important;
}

.login-card .ant-tabs-content-holder {
  padding: 24px;
}

.back-arrow {
  position: absolute;
  top: 20px;
  left: 2%;
}

.login-icons {
  background-color: #f2f2f7;
  border-radius: 14px;
  padding: 14px;
  cursor: pointer;
}

.card-container {
  padding: 32px;
}

.phone-ver-wrapper > .ant-col:not(:last-child) {
  margin-inline-end: 36px;
}

.phone-ver-input {
  width: 30px !important;
  height: 40px !important;
  font-size: 2rem !important;
  text-align: center !important;
  padding: 4px !important;
  margin: 1rem 0 !important;
  margin-inline-end: 0.5rem !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 3px solid #d8d8d8 !important;
}

.phone-ver-input:focus {
  box-shadow: none !important;
  border-bottom: 3px solid #3a5ee3 !important;
}

.domain-name-check {
  position: absolute;
  bottom: 0px;
  transition: all 0.2s ease-in-out;
}

.domain-example {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  max-width: 300px;
}

.card-container .ant-select-selector {
  background-color: #f2f2f7 !important;
  border-radius: 14px !important;
  border: none !important;
}

@media only screen and (max-width: 1366px) {
  .login-main-container {
    max-width: 1300px;
  }
}

@media only screen and (max-width: 768px) {
  .login-main-container {
    padding: 2rem 0.5rem;
  }

  .card-container {
    padding: 32px 12px;
  }
}
